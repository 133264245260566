/* LoadingStyles.css */
.loadingContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black; /* Full screen black background */
}

.loadingImage {
    width: 500px; /* Set image width to 500px */
    margin-bottom: 20px; /* Space between image and loading text */
}

.loadingTextWithDots {
    color: white; /* White text color */
    font-size: 60px; /* Font size of loading text */
    display: flex; /* Align text and dots horizontally */
    align-items: flex-end; /* Align items to the bottom */
}

.dotsContainer {
    height: 30px; /* Enough height to align dots at the bottom */
    display: flex; /* This will align dots horizontally */
    align-items: flex-end; /* Align dots at the bottom */
}

.dot {
    background-color: white; /* White color dots */
    height: 15px; /* Height of the dots */
    width: 15px; /* Width of the dots */
    border-radius: 50%; /* Make dots circular */
    margin: 0 5px; /* Spacing between dots */
    animation: dotFlashing 1s infinite alternate;
}

.dot:nth-child(1) { animation-delay: 0s; }
.dot:nth-child(2) { animation-delay: 0.2s; }
.dot:nth-child(3) { animation-delay: 0.4s; }

@keyframes dotFlashing {
    0% { opacity: 0; }
    50%, 100% { opacity: 1; }
}

.errorContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* White text color for the error message */
    background-color: red; /* Red background for error */
    font-size: 24px; /* Size of the error text */
}

